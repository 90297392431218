import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { ApiService } from "../../Components/Services/apiservices";
import DataContext from "../../Components/Elements/context";
import AddressFrom from "./address_form";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import AddressModal from "../../Components/Modals/address_modal";
import OrderSummary from "./order_summary";
import Help from "../Account/help";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

const Checkout=()=>{
    const { Razorpay } = useRazorpay();
    const Navigate = useNavigate();
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const [hideShowLogin, setHideShowLogin] = useState(false);
    const [emailFocused, setEmailFocused] = useState(false);
    const dataArray = sessionCartData();
    const [rowUserData, setRowUserData] = useState({});
    const [userDefaultAddress, setUserDefaultAddress] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("online");
    const [continuePaySpinnerLoading, setContinuePaySpinnerLoading] = useState(false);
    const contextValues = useContext(DataContext);
    const [textarea, setTextarea] = useState('');
    const [selectedValue, setSelectedValue] = useState(2);

    const [userLoginDetails, setUserLoginDetails] = useState({
        user_email: "",
        user_password: "",
        session_data: dataArray[1],
        user_type: 1,
    });
    
    
    useEffect(() => {
        if (didMountRef.current) {
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
            if (localStorage.getItem("USER_TOKEN")) {
                getUserData();
                getUserAddress();
            } else {

                let address = JSON.parse(localStorage.getItem("RUKMAN_GUEST_CHECKOUT"));
                
                setUserDefaultAddress(address.user_address)
                if (dataArray[1] && dataArray[1].length > 0) {
                } else {
                    navigate("/");
                }
            }
        }
        didMountRef.current = false;
    }, [contextValues]);


    const getUserAddress = () => {
        
        ApiService.fetchData("get-user-default-address").then((res) => {            
            if (res.status === "success") {
                if (res.resUserAddress && res.resUserAddress.ua_id > 0) {
                    //contextValues.setAddressSession(res.resUserAddress);
                    setUserDefaultAddress(res.resUserAddress)
                }
            } else if(res.status=='error' && res.message==='session_expired') {
                localStorage.removeItem("USER_TOKEN");
                navigate("/");
            }
        }).catch((error)=>{});
        
    };
    
    const logout = () => {
        const confirmed = window.confirm("Are you sure you want to log out");
        if (confirmed) {
            ApiService.fetchData("logout").then((res) => {
                localStorage.removeItem("USER_TOKEN")
                window.location.reload()

            })
        }
    }


    const getUserData = () => {
        ApiService.fetchData("get-user-data").then((res) => {
            if (res.status == "success") {
                setRowUserData(res.rowUserData);
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ['ua_email']: res.rowUserData.user_email,
                }));
            }else if(res.status=='error' && res.message==='session_expired') {
                localStorage.removeItem("USER_TOKEN");
                navigate("/");
            } 
        }).catch(()=>{});
    };

    const ContinueToPay = () => {
        //toast.success("Working on it");

        const dataString = {
            parsedCartSession: contextValues.cartSessionData, 
        };
        console.log(dataString);
        ApiService.postData("checkOrderSummary", dataString).then((res) => {
            if (res.status === "success") {

                contextValues.setCartSessionData(res.updatedSessionData)
                contextValues.setCartSummary(res.cartSummary) 
                const hasItemWithStatusTrue = res.updatedSessionData.some((item) => {
                    return (item.product_item_status === true);
                });

                if (!hasItemWithStatusTrue) {
                    const dataString = {
                        amount: parseFloat(res.cartSummary.itemTotal) +
                            (contextValues.settingData && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(res.cartSummary.total_amount)
                                ? parseFloat(contextValues.settingData.admin_notzone_amount)
                                : 0) - parseFloat(res.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
                        paymentMethod: paymentMethod,
                        shippingCharge: (contextValues.settingData && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(res.cartSummary.total_amount)
                        ? parseFloat(contextValues.settingData.admin_notzone_amount)
                        : 0),
                        couponAmount: parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
                        cartSummary: res.cartSummary,
                        parsedAddressSession: userDefaultAddress,
                        parsedCartSession: res.updatedSessionData,
                        parsedCouponSession: contextValues.couponSession ? contextValues.couponSession : { discount_amount: 0.0, promo_id: 0, promo_code: "", cart_amount: 0.0 },
                        textarea: textarea
                    };

                    
                    if (paymentMethod === 'cod') {
                        ApiService.postData("makeCodOrder", dataString).then((res) => {
                            if (res.status === "success") {
                                setContinuePaySpinnerLoading(false)
                                Navigate('/thankyou/' + res.order_number)
                            } else {
                                toast.error(res.message)
                                setContinuePaySpinnerLoading(false)
                            }
                        });
                    }else{
                        ApiService.postData("createtemporder", dataString).then((res) => {
                            if (res.status === "success") {
                                //window.location.href = res.payment_link;
                                handlePayment(res.temp_trans_order_id, res.temp_razorpay_order_id)
                                setTimeout(() => {
                                    setContinuePaySpinnerLoading(false);
                                }, 4000);
                            } else {
                                toast.error(res.message)
                                setContinuePaySpinnerLoading(false);
                            }
                        });
                    }
                } else{
                    setContinuePaySpinnerLoading(false); 
                }
            } else {
                setContinuePaySpinnerLoading(false);
            }
        });
        setContinuePaySpinnerLoading(true)
    }

    const handlePayment = async (temp_trans_order_id,temp_razorpay_order_id) => {
        
        const options = {
            key: contextValues.settingsData.site_key,
            amount: 0,
            currency: "INR",
            name: "RUKMAN",
            description: "RUKMAN",
            image: contextValues.settingsImageBaseUrl + contextValues.settingsData.logo,
            order_id: temp_razorpay_order_id,
            handler: function (response) {
                RazorPayProcess(response, temp_trans_order_id, paymentMethod);
            },
            prefill: {
                name: contextValues.userAddressDetails.ua_fname + ' ' + contextValues.userAddressDetails.ua_lname,
                email: contextValues.userAddressDetails.ua_email,
                contact: contextValues.userAddressDetails.ua_mobile,
            },
            notes: {
                address: "RUKMAN",
            },
            theme: {
                color: "#ce8992",
            },
            method: {
                netbanking: true,
                card: true,
                upi: true,
                wallet: true,
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert("Payment Failed, Please try again!");
            //alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });

        rzp1.open();
    };

    const RazorPayProcess = (response, orderId) => {
        

        const paymentData = {
            paymentId: response.razorpay_payment_id,
            orderId: orderId,
            cartSummary: contextValues.cartSummary,
            paymentMethod: paymentMethod,
            shippingCharge: (contextValues.settingData && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(contextValues.cartSummary.total_amount)
                            ? parseFloat(contextValues.settingData.admin_notzone_amount)
                            : 0),
            parsedAddressSession: contextValues.userToken ? contextValues.userSelectedAddress : contextValues.addressSession,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: contextValues.couponSession ? contextValues.couponSession : { discount_amount: 0.0, promo_id: 0, promo_code: "", cart_amount: 0.0 },
            parsedGiftCardSession: contextValues.GiftcardSession ? contextValues.GiftcardSession : { gt_amount: 0.0, gt_id: 0, gt_giftcard_code: "" },

        };
        // 
        // return false;
        // setContinuePaySpinnerLoading(true)
        ApiService.postData("paymentProcessing", paymentData).then((result) => {
            if (result.status === "success") {
                localStorage.removeItem("GUEST_CHECKOUT")
                localStorage.removeItem("CART_SESSION")
                localStorage.removeItem("ADDRESS_SESSION")
                localStorage.removeItem("COUPON_SESSION")
                localStorage.removeItem("GIFTCARD_SESSION")
                setContinuePaySpinnerLoading(false);
                Navigate('/thankyou/' + orderId);
            } else {
                setContinuePaySpinnerLoading(false);
            }
        });
    };

    const selectOption = (e) => {
        setSelectedValue(e)
        if(e==2){
            setPaymentMethod("online")
        }else{
            setPaymentMethod("cod")
        }
    }


    return( <div className="checkour-container">
        <header class="checkout-header">
            <div className="checkoutLogo">
                <a href="/">
                    <img src={contextValues.settingsData?.logo ? contextValues.settingsImageBaseUrl + contextValues.settingsData?.logo : '/img/logo.png'}></img>
                </a>
            </div>
            <ul className="checkoutStep">
                <li class="active">
                    <a href="/cart/address">01. Address</a>
                </li>
                <li class="active">
                    <a href="javasctipt:void(0)">02. Payment</a>
                </li>
            </ul>
        </header>
        <div className="checkout-wapper">
            <div className="left">
            {contextValues.userToken ?
                <div className="row g-3">
                    <div className="col-lg-12">
                        <div className="flotting-group mb-10">
                            <p>Account <span className="text-underlined" onClick={(e) => logout()}>Logout</span></p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                            <h5 className="mb-0">{rowUserData.user_fname}</h5>
                            <p className="mb-0 tx-14">{rowUserData.user_email}</p>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            {userDefaultAddress ?
                <div className="row g-3 mt-20">
                    <div className="col-lg-12 ">
                        <div className="cartSectionTitle">
                            <h5 className="mb-0 tx-18">Shipping Details</h5>
                            <Link to="/cart/address" className="changelink">Change</Link>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <div className={`checkoutAddress ${userDefaultAddress.ua_default_address == 1 ? 'active' : ''}`}>
                                    <h6 className="mb-1 tx-14 fw400 tx-uppercase">{userDefaultAddress.ua_fname} {userDefaultAddress.ua_lname}</h6>
                                    <p className="mb-0 tx-14">{userDefaultAddress.ua_apartment}, {userDefaultAddress.ua_complete_address}, {userDefaultAddress.ua_city_name},</p>
                                    <p className="mb-1 tx-14">{userDefaultAddress.ua_state_name}, {userDefaultAddress.ua_country_name} - {userDefaultAddress.ua_pincode}</p>
                                    <p className="tx-gray mb-0 tx-14">Mobile No: {userDefaultAddress.ua_mobile}</p>
                                </div>
                            </div>
                      
                        </div>
                    </div>
                </div>
                :
                <></>
            }

                <div className="row g-3 mt-20">
                    <div className="col-lg-12 ">
                        <div className="cartSectionTitle">
                            <h5 className="mb-0 tx-18">Payment Options</h5>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div class="paymentoptionbox">
                            <div class="paymentoptionbox-header" onClick={(e) => selectOption(1)}>                            
                                <div class="ml-15 d-flex gap-3">
                                    <div class="radioBox">
                                        <input type="radio" checked={selectedValue === 1} id="radio-1" name="payment_option" data-type="COD" value="1" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h6 class="mb-0 tx-16 fw400" for="radio-1">Cash on Delivery (COD)</h6>
                                        <p class="mb-0 tx-gray-500 tx-12">Pay after delivery</p>
                                    </div>
                                </div>
                                <div class="paymentoptionimg">Cash on delivery charges<b>₹40</b></div>
                            </div>
                        </div>
                        <div class="paymentoptionbox">
                            <div class="paymentoptionbox-header" onClick={(e) => selectOption(2)}>                            
                                <div class="ml-15 d-flex gap-3">
                                    <div class="radioBox">
                                        <input type="radio" checked={selectedValue === 2} id="radio-2" name="payment_option" data-type="Online" value="2" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h6 class="mb-0 tx-16 fw400" for="radio-2">Pay Online</h6>
                                        <p class="mb-0 tx-gray-500 tx-12">Pay using Credit card, Debit card, UPI</p>
                                    </div>
                                </div>
                                <div class="paymentoptionimg">Cash on delivery charges<b>₹40</b></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <button type="button" class="btn btn-primary btn-full btn-lg paynow loaderbutton" onClick={(e) => ContinueToPay()}>{continuePaySpinnerLoading ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#fff'} color={'#fff'} size={30} /></div> : 'Pay Now'}</button>
                    </div>
                </div>
            </div>
            <OrderSummary></OrderSummary>
        </div>
        <Help />
    </div>)
}

export default Checkout