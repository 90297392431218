import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { addToCart, addToCartSession, minusToCart, minusToCartSession } from "../../Components/Elements/add_to_cart";
import { ApiService } from "../../Components/Services/apiservices";
import DataContext from "../../Components/Elements/context";
const OrderSummary = () => {
    const dataArray = sessionCartData();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const contextValues = useContext(DataContext)
    const [promoCode, setPromoCode] = useState({ promo_code: "", });
    const [couponSpinnerLoading, setCouponSpinnerLoading] = useState(false);
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            contextValues.setCouponSession(dataArray[2])
            if (localStorage.getItem("USER_TOKEN")) {
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        }
        didMountRef.current = false;
    }, []);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: null,
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                if(res.data.resCartData.length == 0){
                    navigate("/cart");
                   
                }
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }

    const minusToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: addproduct.product_image,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: addproduct.product_variation,
                product_category_id: addproduct.product_category_id,
                selected_variation: addproduct.selected_variation,
                quantity: addproduct.quantity,
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

   

    return (
        <>
        {spinnerLoading ? <Loader type="spinner-default" bgColor={'#000000'} color={'#000000'} size={50} />
        :
            contextValues.cartSessionData.length > 0 &&
                <div className="right">
                    <div className="rightCheckoutPanel">
                        {contextValues.cartSessionData.map((value, index) => {
                            return (
                                <>
                                <div className="checkoutProduct" key={index}>
                                    <div className="checkoutProductMedia">
                                        <a href={'/product/' + value.product_slug}>
                                            <img src={value.product_image}></img>
                                        </a>
                                    </div>
                                    <div className="checkoutProductContent">
                                        <h2 className="title">
                                            <a href={'/product/' + value.product_slug}>{value.product_name}</a>
                                        </h2>
                                        {value.product_type == 1 && value.selected_variation && value.selected_variation.length > 0 ?
                                            <>
                                                {value.selected_variation.map((value, index) => {
                                                    return (<div className="tx-gray-500 mb-10 tx-13" key={index}>{value.attr}: {value.terms}</div>)
                                                })}
                                            </>
                                            : null}
                                        {value.product_availability_status == 0 && (<p className="tx-12 tx-primary">If the design is not readily available it will be made to order, which will take us approximately 25-30 days</p>)}
                                        <div className="checkoutProductFooter">
                                            <div className="checkoutProduct-price">
                                                <ins className="new-price">₹{(value.product_selling_price)}</ins>
                                                {Number(value.product_price) > Number(value.product_selling_price) && (<del className="old-price">₹{(value.product_price)}</del>)}
                                            </div>
                                            <div className="checkoutProductqty">
                                                <span onClick={(e) => minusToCartProcess(value)}><i className="ri-subtract-line"></i></span>
                                                <span>{contextValues.spinnerCubLoader == value.product_id ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={30} /> </div> : value.quantity}</span>
                                                <span onClick={(e) => addToCartProcess(value)}><i className="ri-add-line"></i></span>
                                            </div>
                                        </div>
                                        {value.product_item_status && <div class="alert alert-warning" role="alert">{value.product_item_status_message}</div>}
                                    </div>
                                    
                                </div>
                                </>
                            );
                        })}
                        {/* <div className="applyofferbox">
                            <input type="text" placeholder="Enter Coupons Code" name="promo_code" value={promoCode.promo_code} onChange={(e) => onTodoChangePromo(e)} ></input>
                            <button className="applyofferButton btn-primary btn" type="button" onClick={(e) => applyCouponTyping()}>{couponSpinnerLoading ? <img src="/img/loder01.gif" width="60px" height="11px" /> : 'APPLY'}</button>
                        </div> */}
                        {/* {contextValues.couponSession && contextValues.couponSession.promo_id > 0 &&
                            <div className="d-flex justify-content-between mb-20 mt-20">
                                <div>
                                    <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                    <span className="tx-12 tx-green">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                </div>
                                <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                            </div>
                        } */}
                        <ul className="checkputPricebox">
                            <li>
                                <span>Shipping</span>
                                <span>₹{(contextValues.cartSummary.total_amount && contextValues.settingsData.admin_shipping_free && parseFloat(contextValues.settingsData.admin_shipping_free) > parseFloat(contextValues.cartSummary.total_amount) ? parseFloat(contextValues.settingsData.admin_notzone_amount) : 0)}</span>
                            </li>
                            <li>
                                <span>Subtotal ({contextValues.cartSessionData.length} items)</span>
                                <span>₹{(contextValues.cartSummary.itemTotal)}</span>
                            </li>
                            <li>
                                <span>Discount </span>
                                <span>-₹{(contextValues.cartSummary.discount)}</span>
                            </li>
                            {contextValues.couponSession && contextValues.couponSession.discount_amount > 0 && (
                                <li>
                                    <span>Coupon Discount </span>
                                    <span>-₹{(contextValues.couponSession.discount_amount)}</span>
                                </li>
                            )}
                           
                            <li>
                                <span className="tx-16 fw600">Total</span>
                                <span className="tx-16 fw600">₹{(contextValues.cartSummary.total_amount + (contextValues.cartSummary.total_amount && contextValues.settingsData.admin_shipping_free && parseFloat(contextValues.settingsData.admin_shipping_free) > parseFloat(contextValues.cartSummary.total_amount) ? parseFloat(contextValues.settingsData.admin_notzone_amount) : 0) - (contextValues.couponSession && contextValues.couponSession.discount_amount > 0 ? contextValues.couponSession.discount_amount : 0) - (contextValues.GiftcardSession && contextValues.GiftcardSession.gt_amount > 0 ? contextValues.GiftcardSession.gt_amount : 0))}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderSummary